/* eslint-disable global-require */
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import {
  isMultiColorActive,
  defaultColor,
  isDarkSwitchActive,
} from './constants/defaultValues';
import { getCurrentColor, setCurrentColor } from './helpers/Utils';

const color = (isMultiColorActive || isDarkSwitchActive) ? getCurrentColor() : defaultColor;
setCurrentColor(color);

const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

const returnUrl = getQueryVariable("returnUrl");
console.log(returnUrl)
if (returnUrl) {
  localStorage.setItem("returnUrl", returnUrl)
}else{
  localStorage.removeItem("returnUrl")
}

const token = getQueryVariable("token");
if (token) {
  localStorage.setItem("token", token);
}

const utm_source = getQueryVariable("utm_source");
if (utm_source) {
  localStorage.setItem("utm_source", utm_source)
}else{
  localStorage.removeItem("utm_source")
}

if ( !localStorage.getItem('token') )
{
  window.open(`${process.env.REACT_APP_LOGIN_URL}/?returnUrl=${process.env.REACT_APP_HOST_URL}`,'_self')
}


const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require('./AppRenderer');
  });
};
render();
